#warteliste-modal.ReactModalConfirmation {
    margin: auto;
    width: 700px !important;
}

#warteliste-modal .ReactModalConfirmation__container {
    max-width: unset !important;
}

.customInput {
    box-shadow: none;
    border-radius: 0;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: #000;
    width: 100%;
    border: 2px solid #000;
    padding: 0.8em;
    max-width: unset !important;
}

.customLabel {
    position: relative;
    display: table;
    top: 0;
    left: 0.8em;
    margin-top: 10px;
    z-index: 50;
    padding: 0 0.2em;
    transform: translateY(7px);
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1em;
    letter-spacing: 2px;
    color: #000;
    text-transform: uppercase;
    background-color: #fff;
    max-width: fit-content !important;
}

.custom-notification-primary {
    background: rgb(0, 120, 200) !important;
    display: flex !important;
}

.custom-notification-alert-message {
    color: rgb(255, 255, 255);
    flex-direction: column;
    padding: 8px 24px 8px 8px;
    width: 100%;
}

.customLabel-quantity {
    max-width: 120px !important;
}

.custom-linkbtn-disable {
    font-weight: 500 !important;
}