@import "~chamaeleon/styles/base/postSans";
@import "~chamaeleon/styles/Styles";
@import "~simple-lightbox/dist/simpleLightbox.min.css";


.TextBlock{
  font-family: "PostSans", sans-serif;
  background-color: $post-gelb;
}

.homelabel{
  padding-top: 30px;
}
.homelabel p{
  margin-bottom: 0px !important;
}
.homelabel h3{
  margin-bottom: 0px !important;
}

.TextBlock.plain:first {
  /* margin-bottom: -200px; */
}

.TextBlock.plain {
  background-color: transparent;
  /* position: relative;
  top: -210px;
  z-index: 999999; */

  div.container.homelabel {
    padding-top: 0 !important;
    margin-top: 0;

    h1 {
      padding-top: 0;
      margin-top: 0;
      display: none;
    }

    h2, h3, p {
      font-family: PostSans, sans-serif;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      font-variant-caps: normal;
      font-weight: bold;
    }

  }
}

.TextBlock.move_up {
  margin-top: -190px;
  position: relative;
  z-index: 1;
}


@media (max-width: 340px) {
  .TextBlock.move_up {
    margin-top: -140px;
  }
}

@media (min-width: 341px) and (max-width: 639px) {
  div.header_btn {
    height: 80px;
  }

  .TextBlock.move_up {
    margin-top: -160px;
  }
}
