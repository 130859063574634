@import '~chamaeleon/styles/base/postSans';
@import "~chamaeleon/styles/base/_mixins";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "~chamaeleon/styles/base/_forms";
@import '~chamaeleon/styles/Styles';
@import "~react-image-gallery/styles/scss/image-gallery.scss";


.content__container {
  font-family: "PostSans", sans-serif;
}

.linkbtn__secondary.disabled{
  border: 2px solid #666 !important;
}

div.ProductDetail {
  width: 80%;
  height: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  display: inline-flex;
}
@media (min-width: 1024px) {
  .fwTeaser {
    padding: 50px 0 0 0 !important;
  }
}

.productdetailsbutton{
  margin-top:20px;
}

.productdetails_onchain{
  padding-top: 20px;
}

.fwTeaser {
  background-color: #ffffff !important;
}

.fwTeaser__image img {
  height: auto !important;
}

@media (max-width: 769px){ 
  .fwTeaser__image img {
    padding-left: 0% !important;
  }
}
@media (min-width: 770px) and (max-width: 899px){ 
  .fwTeaser__image img {
    padding-left: 5% !important;
  }
}
@media (min-width: 900px) and (max-width: 1023px){   
  .fwTeaser__image img {
    padding-left: 15% !important;
  }
}
@media (min-width: 1024px) and (max-width: 1100px){   
  .fwTeaser__image img {
    height: 335px !important;
    padding-left: 5% !important;
  }
}
@media (min-width: 1101px) and (max-width: 1249px){   
  .fwTeaser__image img {
    height: 350px !important;
    padding-left: 10% !important;
  }
}
@media (min-width: 1250px){   
  .fwTeaser__image img {
    padding-left: 5% !important;
  }
}

/*
.fwTeaser__image {
  height: 450px !important;
}
*/

/*
.fwTeaser__image_home img {
  height: 350px !important;
}
*/

.image-gallery-thumbnail-image {
  height: 150px;
  max-height: 150px;
}

.fwTeaser__image {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
}

.fwTeaser__content__richtext .productdetail{
  margin-bottom: 0 !important;
}

.cham-form.product-details-form {
  margin-bottom: 20px !important;
}

.fwTeaser__content__richtext ul.checkmarks {
  padding-left: 5px !important;
}

div.ProductDetail_Product {
  text-align: left;
  margin-left: 50px;
}

div.ProductDetail_Name {
  font-weight: bold;
}

div.ProductDetail_Number {
  font-style: italic;
  font-size: 80%;

  margin-bottom: 25px;
}

div.ProductDetail_Price {
  font-weight: bold;
}

div.ProductDetail input {
  width: 30px;
  margin-right: 10px;
}

input[name=qty] {
  width: 250px;
  margin-bottom: 10px;
}

.main .linkbtn linkbtn__secondary {
  float: right;
}

div.minicart {
  margin-left: -25px;
  margin-right: -25px;
}

div.image-gallery {
  width: 100%;
}

.image-gallery-svg {
  height: 60px !important;
  width: 30px !important;
}
.image-gallery-left-nav, .image-gallery-right-nav {
  padding: 0 0 !important;
}

div.image-gallery-container {
  position: relative;
}

div.image-gallery-container #productImageBadge {
  width: 90px;
  top: 0 !important;
  position: absolute !important;
  right: 0 !important;
  z-index: 2 !important;
}

div.card.card__teaser #productImageBadge {
  width: 90px;
  top: -3px !important;
  position: absolute !important;
  right: 0px !important;
  z-index: 2 !important;
  margin: 20px;
}

.image-gallery-image img.iiz__img {
  width: 100%;
  object-fit: contain;
  max-height: calc(100vh - 80px);
}

@media (max-width: 499px){ 
  .image-gallery-image img.iiz__img {
    width: 100%;
    object-fit: contain;
    max-height: 250px;
  }
}

@media (min-width: 500px) and (max-width:767px){ 
  .image-gallery-image img.iiz__img {
    width: 100%;
    object-fit: contain;
    max-height: 300px;
  }
}

.modal-customization {
    top: 15% !important;
}