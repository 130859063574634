@import "~chamaeleon/styles/base/postSans";
@import "~chamaeleon/styles/Styles";

body {
  font-family: "PostSans", sans-serif !important;
  color: #666;
  font-size: 16px;
  line-height:1.56em;
}

@media (max-width: 768px){
  body {
      padding-bottom: 0 !important;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#content:not(:empty) + .spinner {
  display: none;
}

.spinner {
  margin-top:90px;
  width: 100%;
  text-align: center;
  align-content: center;
  height: 800px;
  background-color: rgba(255, 255, 255, 0.8);
}

.spinner > div {
  position: absolute;
  width:100%;
  height:100%;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 0.5em solid rgba(255,222,0, 0.0);
  border-left: 0.5em solid #ffde00;
  border-radius: 50%;
  animation: load8 0.8s infinite linear;
}

span.logoSpacer{
  margin-right: 25px;
}

.zahlung-und-versand-info__images {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 194px;
  justify-content: space-between;
}

.zahlung-und-versand-info__image {
  height: 65px;
  margin-bottom: 15px;
  width: 87px;
}
.zahlung-und-versand-info__image img {
  max-width: 100%;
  height: auto;
  max-height: 100%;
}
.zahlung-und-versand-info__content {
  font-size: 14px;
  margin-bottom: .5rem;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.cham-form.zusammenfassung {
  .cham-form .select__input.input-validation-error select, 
  form[data-sc-fxb] .select__input.input-validation-error select, 
  form.cham-form .select__input.input-validation-error select {
    border-color: #d20019 !important;
  }
}

.confirm-buttons button {
  max-width: 100%;
  width: 100%;
  margin: 0 !important;
}

.headerspace{
  margin-top:90px;
}

.vesandinfo-btn-container {
  margin-bottom: 50px;
}

.versandinfo-separator {
  border: 0; 
  border-top: 1px solid #ddd;
}
.hidden{
  display:none !important;
}