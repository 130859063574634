@import "~chamaeleon/styles/base/postSans";
@import "~chamaeleon/styles/Styles";


.footer__container {
  font-family: "PostSans", sans-serif;
}

.footer__title {
  font-family: "PostSans", sans-serif;
  text-align: center;
  font-size: 16px;
  margin-bottom: 35px;
  text-transform: uppercase;
}

.footer__title a {
  text-decoration: none;
}

.footer__title a:hover {
  color: #0056b3;
}

.footer__col{
  padding-top: 20px;
}