@import "~chamaeleon/styles/base/postSans";
@import "~chamaeleon/styles/Styles";


.Headerbar_logo {
    width: 70px;
    @media (max-width: 991.98px) {
        .Headerbar_logo {
          align-self: center;
        }
      }
  }


