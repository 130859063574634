/*
@font-face {
  font-family: PostSans;
  src: url(PostSans-Regular.woff);
}

@font-face {
  font-family: PostSans-Bold;
  src: url(PostSans-Bold.woff);
}
*/

article.countdown {
  margin: 0 auto;
  height: 100vh;
  background-color: #FFDE00;

  a {
    color: #dc8100;
    text-decoration: none;
  }

  a:hover {
    color: #333;
    text-decoration: none;
  }

  img {
    max-width: 65vw;
    min-width: 45vw;
    height: auto;
  }

  div.countdown {
    margin-top: 15px;
    font-size: 470%;
    font-family: "PostSans-Bold", sans-serif;
    font-weight: bold;
    color: #000000;
  }

  div.alert {
    margin-top: 35px;
    font-size: 280%;
    font-family: "PostSans-Bold", sans-serif;
    font-weight: bold;
    color: #000000;
  }

  div.alert {
    display: none;
  }

  .countdown_center_image {
    padding-top: 25vh;
    text-align: center;
  }

  @media only screen and (max-width: 480px) {
    div.countdown {
      font-size: 300%;
    }

    div.alert {
      font-size: 150%;
    }
  }

  @media only screen and (min-width: 800px) {
    img {
      max-width: 35vw;
      min-width: 30vw;
      height: auto;
    }
  }

  @keyframes blinky1 {
    99% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes blinky2 {
    49% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
  }

  /* general styling */
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  .container-home {
    margin: 0 auto;
    text-align: center;
  }

  h1 {
    font-weight: normal;
    letter-spacing: .125rem;
    text-transform: uppercase;
  }

  li {
    display: inline-block;
    font-size: 1.5em;
    list-style-type: none;
    padding: 1em;
    text-transform: uppercase;
  }

  li span {
    display: block;
    font-size: 4.5rem;
  }

  @media all and (max-width: 768px) {
    h1 {
      font-size: 1.5rem;
    }

    li {
      font-size: 1.125rem;
      padding: .75rem;
    }

    li span {
      font-size: 3.375rem;
    }
  }
}
