@import "~chamaeleon/styles/base/postSans";
@import "~chamaeleon/styles/base/_mixins";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "~chamaeleon/styles/base/_forms";
@import "~chamaeleon/styles/Styles";
@import "~chamaeleon/styles/components/Header/headerImage";


//=============Musste ich einfügen weil Zugriff nicht funktioniert============


.headerImage__box {
  position: absolute;
  padding: 0;
  background: none;

  &-text {
    font-size: 28px;
  }
}

.headerImage__box-line,
.breadcrumbs__section {
  display: none;
}

.headerImage__box {
  background-color: $sunflower-yellow-two;
  color: $black;
  padding: 20px;
  padding-bottom: 30px;
  margin: 0;
  position: absolute;
  bottom: 0;

  @include media-breakpoint-only(lg) {
    width: 540px;
    position: absolute;
    left: 0;
  }

  &-line {
    display: block;
    height: 5px;
    background-color: $black;
    margin-bottom: 5px;
  }

  &-text {
    margin: 0;
    font-size: 32px;
    line-height: 1.1em;
    font-weight: bold;
  }
}


//==============================================

.versandinformationen {
  margin-bottom: 2rem;
}

.cham-form .select__input::after,
form[data-sc-fxb] .select__input::after,
form.cham-form .select__input::after {
  right: 20px !important;
}

.cham-form select,
form[data-sc-fxb] select,
form.cham-form select {
  border-color: #000 !important;
}

.confirm-buttons a {
  max-width: 100%;
  width: 100%;
  margin-top: 0;
}

iframe {
  margin-top: 90px !important;
  height: 850px;
  width: 100%;
}

.address-modal{
  margin-top: 90px;
}

#checkout_success{
  margin-top: 90px;
}

#checkout_success_top_banner img {
  width: 100% !important;
  height: 100% !important;
}

#checkout_success .headline {
  font-family: PostSans;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: var(--schwarz);
}

#checkout_success div.row {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--schwarz);
}

#checkout_success div.absatz {
  margin-bottom: 40px;
}

#checkout_success .email {
  font-weight: bold;
}

#checkout_success div.container div.row div.main {
  margin: 0;
}

#checkout_success div.container div.row div.sidebar {
  margin: 0;
  padding: 0;
}

#checkout_success div.container div.row div.sidebar a {
  text-decoration: none;
}

#checkout_success div.sidebar {
  width: 391px;
  margin: 260px 115px 0 73px;
  padding: 40px 116px 398px 34px;
  background-color: var(--grau-hintergrund);
}

@media (max-width: 1023.98px) {
  .versandinformationen input,
  .versandinformationen select {
    max-width: 100% !important;
  }
}

.bestellposition {
  color: $black;
  margin-top: 25px;
 
  &__order {
    border-bottom: 1px solid $very-light-grey3;
    padding-bottom: 10px;
    margin-bottom: 20px;
 
    &:last-child {
      border: 0;
      margin-bottom: 0;
      padding-bottom: 0;
 
      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
 
  &__order-productname,
  &__order-promotion,
  &__order-fee {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-weight: bold;
  }
 
  .left-aligned,
  .right-aligned {
    font-weight: bold;
  }
 
  .right-aligned {
    white-space: nowrap;
  }
 
  &__order-productdata {
    font-size: $base-font-size - 2px;
    margin-top: 10px;
    width: 100%;
    word-break: break-word;
 
    + [class*="promotion"] {
      margin-top: 10px;
    }
 
    p {
      margin-bottom: 10px;
      line-height: 20px;
    }
  }
}

.address_style{
  padding-bottom: 30px;
}

.bestellposition-cart__price{
  flex-grow: 1;
  margin-bottom: 10px;
}

.kassa {
  color: $black;
  line-height: 1.2em;
  margin-bottom: 30px;
 
  &__invoicedelivery {
    margin-bottom: 30px;
  }
 
  &__order-button {
    max-width: 100% !important;
  }
 
  &__place-order {
    position: relative;
 
    .linkbtn__primary {
      max-width: 100% !important;
    }
  }
}

.kassa {
  &__detailedprice {
    //padding-top: ($grid-side-padding);
    border-top: 2px solid $black;
    margin-bottom: 0.4em;
 
    .right-aligned {
      margin-left: $grid-side-padding;
    }
  }
 
  &__detailedprice-row {
    align-items: baseline;
    // font-weight: bold;
    padding-bottom: ($grid-side-padding / 4);
 
    .left-aligned {
      //font-size: 1em;
    }
 
    .right-aligned {
      white-space: nowrap;
      font-weight: bold;
    }
 
    &.total {
      margin-top: ($grid-side-padding + 10px);
 
      .left-aligned {
        font-weight: bold;
        //font-size: 14px;
      }
 
      .right-aligned {
        font-size: 3em;
 
        .fontreset {
          font-size: $base-font-size;
          margin-right: 0.3em;
        }
      }

      .shipping{
        font-size: 2em !important;
      }
    }
  }
 
  .flexspace {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 600px){

  .kassa {   
    &__detailedprice-row {   
      &.total {
        .right-aligned {
          font-size: 1.4rem;
        }  
        .shipping{
          font-size: 1rem !important;
        }
      }
    }
  }
}

.error {
  font-family: "PostSans", sans-serif;
  color: $scarlet;
}

.agb_label{
  margin: 20px 0;
  color: #000;
}

.ReactModal__Overlay {
  z-index: 10000 !important;
}

.fwTeaser__image_success img{
    padding-top: 10px;
    max-width: 300px;
    width: 100%;
}

#checkout_success_top_banner img {
  width: 100% !important;
  height: 100% !important;
}

#checkout_success_top_banner {
  position: relative !important;
}

