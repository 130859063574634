@import "~chamaeleon/styles/base/variables";
@import "~chamaeleon/styles/base/postSans";
@import "~chamaeleon/styles/base/mixins";
@import "~bootstrap/scss/mixins/breakpoints";
@import "~chamaeleon/styles/Styles";



.countdown{
  font-size: 14px;
  font-family: "PostSans", sans-serif !important;
}

.bestellposition-cart {
  color: $black;
  margin-bottom: 25px;

  .bestellposition-cart__product-name{
    a{
      color:black !important;
      text-decoration: none !important;
    }
    a:hover{
      text-decoration:underline !important;
    }
  }

  &__order {
    border-bottom: 1px solid $very-light-grey3;
    padding-bottom: 10px;
    margin-bottom: 20px;
    position: relative;

    &:last-child {
      border: 0;
      margin-bottom: 0;
      padding-bottom: 0;

      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &--error {
      .bestellposition-cart__product-name,
      .bestellposition-cart__product-price {
        color: $scarlet-two;
      }
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;

    &.summary{
      margin-bottom: 0 !important;
    }
  }

  &__product-name,
  &__product-price {
    font-weight: bold;
  }

  &__product-price {
    margin-bottom: 0.2rem;
    font-size: 1rem;
  }

  &__ust-info {
    font-size: 14px;
  }

  &__detail {
    flex-grow: 2;
  }

  &__price {
    white-space: nowrap;
    width: 80px;
    text-align: right;
    font-size: 3rem;
    align-self: center;
  }

  &__order-productdata {
    font-size: $base-font-size - 2px;
    margin-top: 0.3rem;
    width: 100%;
    word-break: break-word;

    p {
      margin-bottom: 10px;
      line-height: 20px;
    }
  }

  &__edit,
  &__remove,
  &__plus,
  &__minus {
    color: $cerulean;
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;

    &:disabled {
      color: $brownish-grey;
    }
  }

  &__controls-desktop {
    display: none;   

    &-left {
      padding-right: 20px;
      text-align: left;
      flex: auto;
    }
    &-right {
      padding-left: 60px;
      text-align: left;
      flex: auto;
    }

  }

  &__controls-mobile {
    display: flex;
    font-size: 3rem;

    &-left {
      text-align: left;
      flex: auto;
    }

    &-right {
      text-align: right;
      flex: auto;
    }
  }

  &__quanitity {
    display: flex;

    &-quantity {
      font-size: 1rem;
      font-weight: bold;
    }

    > div {
      align-self: center;
    }
  }

  @include media-breakpoint-up(lg) {
    &__controls-desktop {
      display: block;
      margin-top: -0.4rem;
      font-size: 2rem;
      text-align: right;
      flex-grow: 1;

      & > div {
        display: inline-block;
      }
    }
    &__product-price {
      font-size: 2rem;
    }
    &__price {
      white-space: nowrap;
      width: 80px;
      text-align: right;
      font-size: 3rem;
      margin-right: 25px;
    }

    &.summary{
      &__price {
        margin-right: 0 !important;
      }
    }

         &__controls-mobile {
          display: none;
        }
  }
  @include media-breakpoint-down(lg) {
    &__price {
      margin-bottom: 0 !important;
      margin-top: 5px;
    }
  }
}

.cart {
  &__cart-total {
    padding-top: ($grid-side-padding / 2);
    border-top: 2px solid $black;
    margin-bottom: 0.4em;
    color: $black;

    .right-aligned {
      margin-left: $grid-side-padding;
    }
  }

  &__cart-total-row {
    align-items: baseline;
    padding-bottom: ($grid-side-padding / 4);

    .right-aligned {
      white-space: nowrap;
      font-weight: bold;
    }

    &.total {
      margin-top: ($grid-side-padding + 10px);

      .left-aligned {
        font-weight: bold;
        //font-size: 14px;
      }

      .right-aligned {
        font-size: 3em;

        .fontreset {
          font-size: $base-font-size;
          margin-right: 0.3em;
        }
      }

      .shipping{
        font-size: 2em !important;
      }
    }
  }

  .flexspace {
    display: flex;
    justify-content: space-between;
  }

  .snackbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1000;
  }
}

.notification{
  &.disabled{
    display:none;
  }
  &.reserved{
    margin-top: 10px !important;
  }
}

.countdown {
  font-size: 14px;
}

.sidebar-is-dark .sidebar__top {
  background-color: #eaeaea;
}
.sidebar__container>:first-child {
  margin-top: 0;
}

.sidebar__top {
  background-color: #f4f4f4;
  margin-top: 35px;
}
.sidebar__main, .sidebar__top {
  padding: 25px 20px 30px;
  margin-bottom: 35px;
}

.sidebar__main>:last-child, .sidebar__top>:last-child {
  margin-bottom: 0;
}
.sidebar__main>:first-child, .sidebar__top>:first-child {
  margin-top: 0;
}
.react-priceinfo {
  width: 100%;
}
.priceinfo {
  background: #ffde00;
  color: #000;
  line-height: 1.2em;
  padding: 25px 20px 20px;
  position: relative;
  margin-bottom: 0;
}
.priceinfo .title {
  margin-top: 0;
  margin-bottom: .7rem;
}
.priceinfo .line-row {
  margin-bottom: .4em;
  display: flex;
  justify-content: space-between;
}

.priceinfo .line-row .right-aligned {
  margin-left: 16px;
}
.priceinfo .right-aligned {
  white-space: nowrap;
}

.priceinfo .line-total {
  align-items: baseline;
  border-top: 2px solid #000;
  font-weight: 700;
  padding-top: 8px;
  display: flex;
  justify-content: space-between;
}

.priceinfo .line-row-total {
  border-top: 2px solid #000;
}
.priceinfo .line-row-total {
  padding-top: .4em;
  margin-bottom: .4em;
  display: flex;
  justify-content: space-between;
}

.priceinfo .line-row-total .right-aligned {
  margin-left: 16px;
}

.priceinfo .line-total .left-aligned {
  font-size: 1em;
}
.priceinfo .line-total .right-aligned {
  font-size: 1.5em;
}
.priceinfo .right-aligned {
  white-space: nowrap;
}

@media (min-width: 768px) {
  .sidebar__top {
      margin-top: 50px;
      margin: 0;
  }
  .sidebar__main, .sidebar__top {
      padding: 35px 30px;
  }
  .priceinfo {
      left: -30px;
      width: calc(100% + 60px);
      padding: 25px 35px 35px;
  }
}

@media (min-width: 1024px){
  .sidebar__top {
      margin-top: 35px;
  }
  .sidebar__main, .sidebar__top {
      padding: 30px 40px;
      margin-bottom: 0;
  }
  .priceinfo {
      left: -40px;
      width: calc(100% + 80px);
      margin-bottom: 1rem;
  }
}