@import "~chamaeleon/styles/base/postSans";
@import "~chamaeleon/styles/Styles";

div.clear {
  clear: both;
  float: none;
}
h1{
  color:black !important;
}

.crypto-banner{
  background-color: $post-gelb;
}

.card__head{
  background-color: $post-gelb;
  border: 1px solid $post-gelb;
}
.card__heading--white {
  display:none;
}

div.ImageTextBlockImage {
  float: left;
}

div.ImageTextBlockImage img {
  max-height: 250px;
}

div.ImageTextBlockText {
  float: left;
}