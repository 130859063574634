@import "~chamaeleon/styles/base/postSans";
@import "~chamaeleon/styles/Styles";
$button-height-s: 69px !important;
$button-height-xs: 100px !important;
$device-width-s: 450px;
$device-width-xs: 300px;
.homelink {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.header_btn {
    width: 100%;
    height: 80px;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 430px) {
        align-items: start;
    }
    .header_linkbtn {
        width: 250px;
        .linkbtn {
            @media (max-width: $device-width-s) {
                height: $button-height-s;
            }
            @media (max-width:$device-width-xs) {
                height: $button-height-xs;
            }
        }
    }
    .header_linkbtn_secondary {
        margin-left: 5vh !important;
        width: 250px;
        .linkbtn {
            @media (max-width: $device-width-s) {
                height: $button-height-s;
            }
            @media (max-width:$device-width-xs) {
                height: $button-height-xs;
            }
        }
    }
}

#header_marginbtn {
    margin-top: 30px;
}