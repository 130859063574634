@import "~chamaeleon/styles/base/postSans";
@import "~chamaeleon/styles/Styles";

div.clear {
  clear: both;
  float: none;
}

.card__head {
  background-color: white;
  border: 1px solid #d9d9d9;
}

.card__heading--white {
  display: none;
}

.header-image img {
  height: 40vh !important;
}

.header-image-right img {
  height: 40vh !important;
}

.header-image .hero__image__bg picture img {
  position: absolute !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.header-image-right .hero__image__bg picture img {
  position: absolute !important;
  top: 52% !important;
  transform: translate(-50%, -50%) !important;
  min-height: 150px;
}

.heading {
  &::before {
    background: #000 !important;
    top: -7px !important;
  }
}

.header_sold {
  display: block;
  margin-top: 34vh;
  text-align: center;
}

.header_sold.disabled {
  display: none;
}

.hero_fullheight {
  height: 100vh;
}

.hero_inherit {
  height: inherit;
}

@media (min-width: 200px) and (max-width: 1024px) {
  .heading {
    &::before {
      background: #000 !important;
      top: -7px !important;
      width: 60% !important;
    }
  }
}

@media (max-width: 376px) and (max-height: 641px) {
  .heading-home {
    font-size: 36px !important;
    color: #000 !important;
  }
  .header-image img {
    margin-top: 5%;
    height: 150px !important;
    margin-bottom: 10%;
  }
  .header-image-right img {
    margin-top: 10%;
    height: 150px !important;
    margin-bottom: 0;
  }
  .header_linkbtn {
    width: 250px;
  }
  .header_linkbtn_secondary {
    margin-left: 5vh !important;
    width: 250px;
  }
  .fwTeaser__image img {
    height: 200px !important;
    padding-left: 0%;
  }
  .hero img {
    height: 100px !important;
    margin-left: 0px;
  }
  p {
    font-size: 18px !important;
  }
}


@media (max-width: 376px) and (max-height: 570px) {
  .heading-home {
    font-size: 36px !important;
    color: #000 !important;
  }
  .header-image img {
    margin-top: 5%;
    height: 150px !important;
    margin-bottom: 10%;
  }
  .header-image-right img {
    margin-top: 10%;
    height: 150px !important;
    margin-bottom: 0;
  }
  .header-image-right .hero__image__bg picture img {
    position: absolute !important;
    top: 55% !important;
    transform: translate(-50%, -50%) !important;
    min-height: 150px;
  }
  .header_linkbtn {
    width: 250px;
  }
  .header_linkbtn_secondary {
    margin-left: 5vh !important;
    width: 250px;
  }
  .fwTeaser__image img {
    height: 200px !important;
    padding-left: 0%;
  }
  .hero img {
    height: 100px !important;
    margin-left: 0px;
  }
  p {
    font-size: 18px !important;
  }
}

@media (max-width: 376px) and (min-height: 642px) {
  .heading-home {
    font-size: 36px !important;
    color: #000 !important;
  }
  .header-image-right .hero__image__bg picture img {
    top: 45% !important;
  }
  .header-image img {
    margin-top: 5%;
    height: 150px !important;
    margin-bottom: 10%;
  }
  .header-image-right img {
    margin-top: 20%;
    height: 200px !important;
    margin-bottom: 10%;
  }
  .header_linkbtn {
    width: 250px;
  }
  .hero img {
    height: 100px !important;
    margin-left: 0px;
  }
  .header_linkbtn_secondary {
    margin-left: 5vh !important;
    width: 250px;
  }
  p {
    font-size: 20px !important;
  }
}


@media (min-width: 377px) and (max-width: 768px) {
  .header-image img {
    margin-top: 5%;
    height: 150px !important;
    margin-bottom: 10%;
  }
  .header-image-right img {
    margin-top: 15%;
    height: 200px !important;
    margin-bottom: 10%;
  }
  .header_linkbtn {
    width: 250px;
  }
  .header_linkbtn_secondary {
    margin-left: 5vh !important;
    width: 250px;
  }
}

@media (min-width: 450px) and (max-width: 780px) {
  .header_sold {
    margin-top: 20vh;
    text-align: center;
  }
}

@media (max-width: 449px) {
  .header_linkbtn {
    width: 250px;
  }
  .header_linkbtn_secondary {
    margin-left: 5vh !important;
    width: 250px;
  }
  .header_sold {
    margin-top: 16vh;

    p {
      font-size: 14px !important;
    }
  }
}

@media (min-width: 530px) and (max-width: 765px) and (min-height: 689px) {
  .header-image img {
    margin-top: 5%;
    height: 150px !important;
    margin-bottom: 10%;
  }
  .header-image-right img {
    margin-top: 10%;
    height: 200px !important;
    margin-bottom: 10%;
  }
  .header_linkbtn {
    width: 250px;
  }
  .header_linkbtn_secondary {
    margin-left: 5vh !important;
    width: 250px;
  }
}

@media (min-width: 766px) and (max-width: 800px) and (min-height: 689px) {
  .header-image img {
    margin-top: 5%;
    height: 150px !important;
    margin-bottom: 10%;
  }
  .header-image-right img {
    margin-top: 0;
    height: 200px !important;
    margin-bottom: 5%;
  }
  .header_linkbtn {
    width: 250px;
  }
  .header_linkbtn_secondary {
    margin-left: 5vh !important;
    width: 250px;
  }
}

@media (min-width: 350px) and (max-width: 450px) {
  .linkbtn {
    height: 69px !important;
  }
}

@media (max-width: 349px) {
  .linkbtn {
    height: 88px !important;
  }
}

@media (max-width: 300px) {
  .header-image-right .hero__image__bg picture img {
    top: 50% !important;
  }
}

@media (max-width: 370px) {
  .headerbar__loginBtn {
    margin-top: -5px;
  }
}


@media (min-width: 380px) and (max-width: 420px) and (min-height: 720px) and (max-height: 830px) {
  .header-image-right .hero__image__bg picture img {
    top: 53% !important;
  }
}

@media (min-width: 768px) and (max-width: 1050px) {
  .header-image-right .hero__image__bg picture img {
    top: 60% !important;
  }
  .hero_fullheight {
    height: 95vh;
  }
}

@media (min-width: 768px) and (max-width: 1050px) and (min-height: 1023px) {
  .header-image-right .hero__image__bg picture img {
    top: 55% !important;
  }
  .hero_fullheight {
    height: 95vh;
  }
}

@media (min-width: 1051px) {
  .header-image-right .hero__image__bg picture img {
    top: 55% !important;
  }
  .hero_fullheight {
    height: 100vh;
  }
}

@media (min-width: 768px) and (max-height: 650px) {
  .header-image-right .hero__image__bg picture img {
    top: 62% !important;
  }
  .hero_fullheight {
    height: 100vh;
  }

}

.heading-home {
  margin-top: 20vh;
  color: #000 !important;
}

.header_btn {
  width: 100%;
  height: 150px;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header_linkbtn {
  width: 250px;
}

.header_linkbtn_secondary {
  margin-left: 10vh;
  width: 250px;
}

// @media screen and (max-width: 600px){
//   .header_linkbtn_secondary {
//     margin-left: 0px !important;
//   }
// }
// @media screen and (max-width: 1000px){
//   .header_linkbtn_secondary_nl {
//     margin-left: 0px !important;
//   }
// }


.header-image {
  min-height: 90vh !important;
  background-color: white;
  opacity: 1;
  z-index: 1000;
  -webkit-animation: header 5s normal forwards;
  animation: header 5s normal forwards;
}

.header-image-right {
  min-height: 90vh !important;
  background-color: white;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  -webkit-animation: header-image 5s normal forwards;
  animation: header-image 5s normal forwards;
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes header {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    z-index: 0;
  }
}

/* Standard syntax */
@keyframes header {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    z-index: 0;
  }
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes header-image {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Standard syntax */
@keyframes header-image {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.header-image img {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.header-image-right img {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.animateArrow {
  font-size: 20px;
  color: $post-gelb;
  position: absolute;
  bottom: 50px;
  left: 50%;
  margin: 0;
  width: 25px;
  height: 25px;
  opacity: 1;
  z-index: 1000;
  -webkit-animation: scrollbutton 1.2s infinite;
  animation: scrollbutton 1.2s infinite;
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes scrollbutton {
  from {
    bottom: 80px;
    opacity: 0
  }
  to {
    bottom: 20px;
    opacity: 1
  }
}

/* Standard syntax */
@keyframes scrollbutton {
  from {
    bottom: 80px;
    opacity: 0
  }
  to {
    bottom: 20px;
    opacity: 1
  }
}