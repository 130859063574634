@import "~chamaeleon/styles/base/postSans";
@import "~chamaeleon/styles/Styles";


@mixin headerbar_sections_desktop_basic_Styles{
  display: flex;
  align-items: center;
  flex-grow: inherit;
  height:91px;
}

.Headerbar_headerbar__container {
  font-family: "PostSans", sans-serif;
  background-color:/*  #ffdc00; */ $post-gelb;
  height: 90px;
  padding: 0 17px 0 21px;
  position: -webkit-fixed;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2000;


  // Headerbar Desktop======================================================================================

  .Headerbar_desktop {
    display: block;

    .Headerbar_headerbar__left  {
      @include headerbar_sections_desktop_basic_Styles;

      .headerbar__links{

        @media (max-width: 768px) {
          float: right;
          margin-top: 30px;
          margin-right: 8px;
      }

      @media (min-width: 768px) and (max-width: 991.98px) {
          float: right;
          margin-top: 20px;
        }
      }
    }

    .Headerbar_headerbar__right{
      @include headerbar_sections_desktop_basic_Styles;

      .headerbar__login{
        
      }
      
      .headerbar__links {
        @media (max-width: 768px) {
            float: right;
            // margin-top: 30px;
            margin-right: 8px;
        }

        @media (min-width: 768px) and (max-width: 991.98px) {
            float: right;
            // margin-top: 20px;
          }
      }
    }


    // Media Queries headerbar desktop=======
    @media (max-width: 991.98px) {
        display: none;
    }
  }


   // Headerbar Mobile======================================================================================

  .Headerbar_mobile {
    display: none;
    height: 100%;
    align-items: center;

    .headerbar__links {
      @media (max-width: 768px) {
          float: right;
          margin-top: 30px;
          margin-right: 8px;
      }
      @media (min-width: 768px) and (max-width: 991.98px) {
          float: right;
          margin-top: 20px;
        }

        .headerbar__linklist{
          li{
            .posticon_Posticon-Avatar-Kreis {
                color: black !important;
                visibility: hidden;
                @media (max-width: 768px) {
                  visibility: visible;
                }  
              }
          }
        
        }
    }


    // Media Queries headerbar mobile=======
    @media (max-width: 991.98px) {
      display:flex
    }
  }



  // ENDE HEADERBAR CONTAINER =======================================================================
}


// ===================

.Headerbar_headerbar {
  display: flex;
  justify-content: space-between;
  height: 90px;
}



.headerbar__linklist li .link__cart .link__cart--number {
  min-height: 20px !important;
  line-height: 1.5em !important;
  padding: 3px !important;
  width: 25px !important;
}

@media (min-width: 768px){
  .headerbar__linklist li .link__cart .link__cart--number {
      right: 0px !important;
      top: 28px !important;
  }
}

.Headerbar_languageSwitch {
  white-space: nowrap;
  margin-left: 10px;
}

.languageSwitch {
  text-transform: uppercase !important;
}

.Headerbar_languageSwitch_language {
  cursor: pointer;
  padding: 3px 6px 1px;
  text-transform: uppercase;
}

.Headerbar_languageSwitch_selected {
  background: #000;
  color: #fff;
  font-weight: 700;
}

.Headerbar_languageSwitch_notselected {
  background: transparent;
  opacity: .28;
}




.Headerbar_searchbar {
  width: 100%;
}

.MobileMenu_humburger {
  display: none;
  position: relative;
  z-index: 104;
}

.MobileMenu_mobileMenuLogo {
  display: flex;
  margin: auto;
  height: 90px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  background: #ffdc00;
}

.Headerbar_humburger {
  padding-left: 20px;
}

// LÖSUNG==========================

@media (min-width: 768px) and (max-width: 1023.98px){
  .headerbar__login a .headerbar__btnText, .headerbar__login button .headerbar__btnText {
      display: block !important;
      font-size: 16px;
  }
}

.headerbar__login.active::before{
  @media (max-width: 991.98px){
    height: 90px !important;
  }
  @media (min-width:991.99px) and (max-width: 1023.98px){
    height: 119.7px !important;
  }
}


.headerbar__login {
  @media (max-width: 991.97px){
      margin-top: -11px;  
    }
    @media (max-width: 768px){
      display: none !important;   
    }
}

// =======================================



@media (min-width: 768px){
  .headerbar__login-submenu {
      padding: 7px 20px 7px;
  }
}

@media (max-width: 768px){
.headerbar__login-submenu {
    top: calc(100% + 24px) !important;
    bottom: auto !important;
    z-index: -1 !important;
    width: 150px !important;
    padding: 7px 20px 7px !important;
    -webkit-transform: translate(0) !important;
    transform: translate(0) !important;
    transition: opacity .25s ease,-webkit-transform .25s ease !important;
    transition: transform .25s ease,opacity .25s ease !important;
    transition: transform .25s ease,opacity .25s ease,-webkit-transform .25s ease !important;
}
}





.MobileMenu_mobileMenuContainer {

}

.MobileMenu_mobileMenuContainer_active {

}

.MobileMenu_mobileMenuOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  z-index: 101;
  visibility: hidden;
  background: rgba(0,0,0,.6);
  transition: all .3s ease-in-out;
}

.MobileMenu_mobileMenuContainer.MobileMenu_mobileMenuContainer_active .MobileMenu_mobileMenuOverlay {
  opacity: 1;
  visibility: visible;
}

.MobileMenu_mobileMenuContent {
  position: fixed;
  top: 0;
  bottom: 0;
  right: -300px;
  background: #fff;
  width: 300px;
  z-index: 102;
  transition: all .3s ease-in-out;
}

.MobileMenu_mobileMenuContainer.MobileMenu_mobileMenuContainer_active .MobileMenu_mobileMenuContent {
  right: 0;
}

.MobileMenu_mobileMenuBody {
  padding: 20px 25px;
  background: #fff;
}

.MobileMenu_humburger > div {
  width: 50px; height: 35px; position: relative; transform: rotate(0deg);
}

.MobileMenu_humburger > div > span:nth-child(1) {
  display: block; height: 3px; width: 100%; background-color: black; transition-timing-function: ease; transition-duration: 0.5s; border-top-left-radius: 0px; border-top-right-radius: 0px; border-bottom-right-radius: 0px; border-bottom-left-radius: 0px; transform-origin: center center; position: absolute; transform: translate3d(0px, 0px, 0px) rotate(0deg); margin-top: -1.5px; background-position: initial initial; background-repeat: initial initial;
}

.MobileMenu_humburger > div > span:nth-child(2) {
  display: block; height: 3px; width: 100%; background-color: black; transition-timing-function: ease-out; transition-duration: 0.125s; border-top-left-radius: 0px; border-top-right-radius: 0px; border-bottom-right-radius: 0px; border-bottom-left-radius: 0px; transform-origin: center center; position: absolute; opacity: 1; top: 17.5px; margin-top: -1.5px; background-position: initial initial; background-repeat: initial initial;
}

.MobileMenu_humburger > div > span:nth-child(3) {
  display: block; height: 3px; width: 100%; background-color: black; transition-timing-function: ease; transition-duration: 0.5s; border-top-left-radius: 0px; border-top-right-radius: 0px; border-bottom-right-radius: 0px; border-bottom-left-radius: 0px; transform-origin: center center; position: absolute; transform: translate3d(0px, 35px, 0px) rotate(0deg); margin-top: -1.5px; background-position: initial initial; background-repeat: initial initial;
}

.MobileMenu_humburger.MobileMenu_humburger_active > div > span:nth-child(1) {
  transform: translate3d(0px, 17.5px, 0px) rotate(45deg);
}

.MobileMenu_humburger.MobileMenu_humburger_active > div > span:nth-child(2) {
  opacity: 0;
}

.MobileMenu_humburger.MobileMenu_humburger_active > div > span:nth-child(3) {
  transform: translate3d(0px, 17.5px, 0px) rotate(-45deg);
}

#up-triangle {
  width: 0; 
  height: 0; 
  top: 0;
  margin-left: 265px;
  border-bottom: 12px solid #0078c8; 
  border-left: 6px solid transparent; 
  border-right: 6px solid transparent; 
  z-index:1000;
}

#up-triangleError {
  width: 0; 
  height: 0; 
  top: 0;
  margin-left: 265px;
  border-bottom: 12px solid #e74c3c;
  border-left: 6px solid transparent; 
  border-right: 6px solid transparent; 
  z-index:1000;
}

.ErrorModalBody{
  border-color: #e74c3c !important;
}

.ErrorModalHeader{
  background-color: #e74c3c !important;
}

.modal{
  z-index: 1000;
}

.ReactModalConfirmation{
  width: 300px !important;
}

@media (max-width: 768px) {
  .headerbar__linklist li{
    padding: 0 5px !important;
  }
  .modal{
    z-index: 1000;
    margin-left: calc(100% - 330px) !important;
  }
  #up-triangle {
    width: 0; 
    height: 0; 
    top: 0;
    margin-left: 240px;
    border-bottom: 12px solid #0078c8; 
    border-left: 6px solid transparent; 
    border-right: 6px solid transparent; 
    z-index:1000;
  }
  #up-triangleError {
    width: 0; 
    height: 0; 
    top: 0;
    margin-left: 240px;
    border-bottom: 12px solid #e74c3c; 
    border-left: 6px solid transparent; 
    border-right: 6px solid transparent; 
    z-index:1000;
  }
}

.headerbar__linklist li{
  border-left: 0px solid #666 !important;
}


@media (min-width: 768px) and (max-width: 991.98px) {
  .modal{
    z-index: 1000;
    margin-left: calc(100% - 290px) !important;
  }
  #up-triangle {
    width: 0; 
    height: 0; 
    top: 0;
    margin-left: 240px;
    border-bottom: 12px solid #0078c8; 
    border-left: 6px solid transparent; 
    border-right: 6px solid transparent; 
    z-index:1000;
  }
  #up-triangleError {
    width: 0; 
    height: 0; 
    top: 0;
    margin-left: 240px;
    border-bottom: 12px solid #e74c3c; 
    border-left: 6px solid transparent; 
    border-right: 6px solid transparent; 
    z-index:1000;
  }
}

@media (min-width: 991.98px) and (max-width: 1439px) {
  .modal{
    z-index: 1000;
    margin-left: calc(87vw - 280px) !important;
  }
  #up-triangle {
    width: 0; 
    height: 0; 
    top: 0;
    margin-left: 240px;
    border-bottom: 12px solid #0078c8; 
    border-left: 6px solid transparent; 
    border-right: 6px solid transparent; 
    z-index:1000;
  }
  #up-triangleError {
    width: 0; 
    height: 0; 
    top: 0;
    margin-left: 240px;
    border-bottom: 12px solid #e74c3c; 
    border-left: 6px solid transparent; 
    border-right: 6px solid transparent; 
    z-index:1000;
  }
}

@media (min-width: 1440px) {
  .modal{  
      margin-left:900px;
  }
}

@media (max-width: 991.98px) {
 
  .MobileMenu_humburger {
    display: block;
  }
}

@media (max-width: 1023.98px){
  .ReactModalConfirmation {
    justify-content: start !important;
    align-items: start !important;
  }
}

.headerbar .Headerbar_link {
  color: #000;
  font-weight: 400;
}

.carticon {
  &.disabled{
    position: relative;
    display: none;
  }
}
@media (min-width: 991.98px){
  .headerbar{
    padding: 0 0 !important;
  }
}


.headerbar__login.active{
  @media (max-width: 1024px){
    display: none !important;
  }
}

.posticon_Posticon-Avatar-Kreis {
  display: block !important;
  font-size: 33px;
  z-index: 99;
}

.posticon_Posticon-Avatar-Kreis.posticon_Posticon-Avatar-Kreis_mobile{
  color:black !important;
  display:none !important;
  
  @media (max-width: 1024px){
    display: block !important;
  }
}

