@import "~chamaeleon/styles/base/postSans";
@import "~chamaeleon/styles/Styles";

.hero {
    font-family: "PostSans", sans-serif;
}

.hero__content--white .heading, .hero__content--white .hero__intro {
    text-align: center;
}
.hero__image__content {
    align-items: baseline !important;
}

.wrapper{
    margin-top: 90px !important;
}

@media (max-width: 768px){   
    .hero img{
      height:200px !important;
    }
  }

/*@media (min-width: 1024px){
    .hero__image__content {
        padding-bottom: 200px;
        position: absolute;
    }
}*/


.placeHolderHome{
    width:100vw;
    height:1000px;
}