@import "~chamaeleon/styles/base/postSans";
@import "~chamaeleon/styles/Styles";

.ProductSliderBlock{
  font-family: "PostSans", sans-serif;
}

div.ProductSliderBlock {
  margin-bottom: 35px;
  margin-left: auto;
  margin-right: auto;
}

div.ProductSliderBlock_ProductSmall {
  border: 1px solid black;
  float: left;
  margin-right: 20px;
  padding: 5px;
}

div.ProductSliderBlock_ProductSmall div.productPrice {
  font-weight: bold;
}

div.ProductSliderBlock_Title {
  font-weight: bold;
}

div.ProductSliderBlock_ProductBig {
  width: 80%;
  background-color: #eeeeee;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 35px;
  padding: 10px;
}

div.ProductSliderBlock_ProductBig div.productImage {
  float: left;
  width: 30%;
}

div.ProductSliderBlock_ProductBig div.productName {
  font-weight: bold;
  font-size: 150%;
}

div.ProductSliderBlock_ProductBig div.productPrice {
  font-weight: bold;
}

div.ProductSliderBlock_ProductBig input {
  width: 30px;
  margin-right: 10px;
}

div.ProductSliderBlock_ProductBig button {
  margin-right: 10px;
}

@media (max-width: 767px){   
  .fwTeaser__image_home img{
      padding-left: 0%;
      margin-left: calc(50vw - 250px) !important;
      width: 400px;
    }
}

@media (min-width: 768px) and (max-width: 1100px){   
  .fwTeaser__image_home img{
      margin-left: calc(50% - 225px) !important;
      width: 500px;
    }
}