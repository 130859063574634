@import "~chamaeleon/styles/base/postSans";
@import "~chamaeleon/styles/Styles";

.ProductSliderBlock{
  font-family: "PostSans", sans-serif;
}

div.ProductSliderBlock {
  margin-bottom: 35px;
  margin-left: auto;
  margin-right: auto;
}

div.ProductSliderBlock_ProductSmall {
  border: 1px solid black;
  float: left;
  margin-right: 20px;
  padding: 5px;
}

div.ProductSliderBlock_ProductSmall div.productPrice {
  font-weight: bold;
}

div.ProductSliderBlock_Title {
  font-weight: bold;
}

div.ProductSliderBlock_ProductBig {
  width: 80%;
  background-color: #eeeeee;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 35px;
  padding: 10px;
}

div.ProductSliderBlock_ProductBig div.productImage {
  float: left;
  width: 30%;
}

div.ProductSliderBlock_ProductBig div.productName {
  font-weight: bold;
  font-size: 150%;
}

div.ProductSliderBlock_ProductBig div.productPrice {
  font-weight: bold;
}

div.ProductSliderBlock_ProductBig input {
  width: 30px;
  margin-right: 10px;
}

div.ProductSliderBlock_ProductBig button {
  margin-right: 10px;
}

.card__head:before {
  padding-bottom: 80%;
}

.card__head{
  background-color: #ffffff;
}