@import "~chamaeleon/styles/base/postSans";
@import "~chamaeleon/styles/Styles";


div.clear {
  clear: both;
  float: none;
}

.card__head{
  background-color: white;
  border: 1px solid #d9d9d9;
}
.card__heading--white {
  display:none;
}
.header-image img{
  height: 40vh !important;
}
.header-image-right img{
  height: 40vh !important;
}

.header-image .hero__image__bg picture img {
  position: absolute !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.header-image-right .hero__image__bg picture img {
  position: absolute !important;
  top: 55% !important;
  transform: translate(-50%, -50%) !important;
  min-height: 150px;
}

.heading{
  &::before{
    background: #000 !important;
    top: -7px !important;
  }
}

@media (min-width: 200px) and (max-width: 1024px) {
  .heading{
    &::before{
      background: #000 !important;
      top: -7px !important;
      width: 60% !important;
    } 
  }
}


@media (max-width: 376px) and (max-height: 641px) {
  .heading-home{
    font-size:36px !important;
    color: #000 !important;
  }
  .header-image img {
      margin-top: 5%;
      height:150px !important;
      margin-bottom: 10%;
  }
  .header-image-right img {
    margin-top: 10%;
    height:150px !important;
    margin-bottom: 0;
  }

  .fwTeaser__image img {
    height: 200px !important;
    padding-left: 0%;
  }
  .hero img {
    height: 100px !important;
    margin-left: 0px;
  }
  p{
    font-size: 18px !important;
  }
}

@media (max-width: 376px) and (min-height: 642px) {
  .heading-home{
    font-size:36px !important;
    color: #000 !important;
  }
  .header-image-right .hero__image__bg picture img {
    top: 50% !important;
  }
  .header-image img {
      margin-top: 5%;
      height:150px !important;
      margin-bottom: 10%;
  }
  .header-image-right img {
    margin-top: 20%;
    height:200px !important;
    margin-bottom: 10%;
  }
  
  .hero img {
    height: 100px !important;
    margin-left: 0px;
  }
  p{
    font-size: 20px !important;
  }
}


@media (min-width: 377px) and (max-width: 768px) {
  .header-image img {
      margin-top: 5%;
      height:150px !important;
      margin-bottom: 10%;
  }
  .header-image-right img {
    margin-top: 15%;
    height:200px !important;
    margin-bottom: 10%;
  }
  
}

@media (min-width: 530px) and (max-width: 765px) and (min-height: 689px) {
  .header-image img {
      margin-top: 5%;
      height:150px !important;
      margin-bottom: 10%;
  }
  .header-image-right img {
    margin-top: 5%;
    height:200px !important;
    margin-bottom: 5%;
  }
}

@media (min-width: 740px) and (max-width: 800px) and (min-height: 689px) {
  .header-image img {
      margin-top: 5%;
      height:150px !important;
      margin-bottom: 10%;
  }
  .header-image-right img {
    margin-top: 0;
    height:200px !important;
    margin-bottom: 5%;
  }
}


.heading-home{
  margin-top:20vh;
  color: #000 !important;

  @media (max-width: 859px){
    margin-top:17vh;
  }

  @media (max-width: 400px){
    margin-top:12vh;
  }
}

.header-image{
  min-height:90vh !important;
  background-color: white;
  opacity: 1;
  z-index: 1000;
  -webkit-animation: header 5s normal forwards;
  animation: header 5s normal forwards;
  
}

.header-image-right{
  min-height:90vh !important;
  background-color: white;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity:0;
  z-index: 1;
  -webkit-animation: header-image 5s normal forwards;
  animation: header-image 5s normal forwards;

}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes header {
 from {
  opacity: 1;
 }
 to {
  opacity: 0;
  z-index: 0;
 }
}
 
/* Standard syntax */
@keyframes header {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    z-index: 0;
  }
}

 /* Safari 4.0 - 8.0 */
@-webkit-keyframes header-image {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
 
 /* Standard syntax */
 @keyframes header-image {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.header-image img{
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.header-image-right img{
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.animateArrow {
  font-size: 20px;
  color: $post-gelb;
  position: absolute;
  bottom: 50px;
  left: 50%;
  margin: 0;
  width: 25px;
  height: 25px;
  opacity: 1;
  z-index: 1000;
  -webkit-animation: scrollbutton 1.2s infinite;
  animation: scrollbutton 1.2s infinite;
}
    
/* Safari 4.0 - 8.0 */
@-webkit-keyframes scrollbutton {
 from {
bottom: 80px;
 opacity: 0
}
 to {
bottom: 20px;
 opacity: 1
}
}

/* Standard syntax */
@keyframes scrollbutton {
 from {
bottom: 80px;
opacity: 0
}
 to {
bottom: 20px;
opacity: 1
}
}

.hero_fullheight{
  height: 90vh;
  @media (max-width: 400px)  {
    margin-top: 10vh !important;
  }
}

.hero_inherit {
  height: inherit;
}
