@import "~chamaeleon/styles/base/postSans";
@import "~chamaeleon/styles/Styles";


.Headerbar_link {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 16px;
    z-index: 99;
    background: transparent;
    padding: 35px 20px;
    /* jpe -- added in addition to class pasted from crypto website */
    text-decoration: none;
    button {
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      border: none;
      color: #000;
      padding: 0;
      background-color: transparent;
      text-decoration: none;
    }
  }

  .headerbar_shop{
    background-color:white;
  }



  .MobileMenu_navItem {
    line-height: 2;
    color: #0078c8;
    display: block;
    text-align: left;
  }